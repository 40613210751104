import "./contactLink.css";

const ContactLink = (props) => {
  let link = "";
  let value = "";

  switch (props.type) {
    case "phone-landline":
      value = "040 60053458";
      link = `tel:${value.replace(" ", "")}`;
      break;
    case "phone-mobile":
      value = "0176 20590579";
      link = `tel:${value.replace(" ", "")}`;
      break;
    case "email":
      value = "malik@psychiater-bergedorf.de";
      link = `mailto:${value}`;
      break;
  }

  return (
    <>
      <a href={link} className="contact-link">
        {value}
      </a>
    </>
  );
};

export default ContactLink;
