import React from "react";
import "./navigationButton.css";
import { useNavigate } from "react-router-dom";

const NavigationButton = (props) => {
  const navigate = useNavigate();
  let style = {};
  switch (props.className) {
    case "navigation-button-desktop":
      style = {
        padding: "1%",
        cursor: "pointer",
        fontSize: "larger",
        transition: "all 0.2s ease-in-out",
      };
      break;
    case "navigation-button-mobile":
      style = {
        paddingRight: "16px",
        cursor: "pointer",
        textAlign: "right",
        fontSize: "larger",
        transition: "all 0.2s ease-in-out",
        position: "absolute",
        top: `${props.index * 30 + 64}px`,
        background: "var(--main-bg-color)",
        minWidth: "-webkit-fill-available",
      };
      break;
  }

  return (
    <>
      <span
        className={props.className}
        style={style}
        onClick={(event) => {
          event.preventDefault();
          if (props.enum.id !== "") {
            window.location.replace(`/#${props.enum.id}`);
          } else {
            navigate(`/${props.enum.name.toLowerCase()}`);
          }
        }}
      >
        {props.enum.name}
      </span>
    </>
  );
};

export default NavigationButton;
