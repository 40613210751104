import PraxisMap from "./components/praxisMap";
import ContactLink from "./components/contactLink";

export const sectionEnum = {
  UEBER: {
    name: "Über Uns",
    id: "ueber",
    html: (
      <div className="text">
        <div className="image-text-container">
          <div className="portrait-image">
            <img src="/maliha-malik.jpeg" alt="" id="portrait" />
          </div>
          <div className="left-text">
            <p>
              Ich bin in Geesthacht geboren und habe pakistanische Wurzeln. Mein
              Medizinstudium absolvierte ich an der Universität des Saarlandes
              und der Universität zu Lübeck. Es war bereits im Studium mein
              Ziel, Psychiaterin zu werden.
            </p>
            <p>
              Direkt nach dem Studium begann ich meine Weiterbildung zur
              Fachärztin für Psychiatrie in Lüneburg und Geesthacht. Später war
              ich als Oberärztin im Krankenhaus Geesthacht tätig. Derzeit bin
              ich Fachärztin für Psychiatrie und Psychotherapie und promoviere
              zum Thema{" "}
              <i>
                Lebensqualität und Lebenslage psychiatrischer Patientinnen und
                Patienten mit pakistanischem Migrationshintergrund im Raum
                Hamburg
              </i>
              .
            </p>
          </div>
        </div>
      </div>
    ),
  },
  WILLKOMMEN: {
    name: "Willkommen",
    id: "willkommen",
    html: (
      <div className="text">
        <p>
          Mein Name ist Maliha Malik. Ich habe diese Privatpraxis gegründet, um
          meinen Patientinnen und Patienten eine individuellere und
          persönlichere Betreuung zu ermöglichen. Seit Februar 2022 führe ich
          meine eigene Privatpraxis in Bergedorf.
        </p>
        <p>
          Sie können mich telefonisch unter{" "}
          <b>
            <ContactLink type="phone-landline" />
          </b>{" "}
          (Festnetz),{" "}
          <b>
            <ContactLink type="phone-mobile" />
          </b>{" "}
          (Handy) oder per E-Mail unter{" "}
          <b>
            <ContactLink type="email" />
          </b>{" "}
          erreichen. Die Praxis befindet sich im 1. Stock am
          <b> Sachsentor 26, 21029 Hamburg-Bergedorf. </b>
        </p>
        <PraxisMap />
      </div>
    ),
  },
  LEISTUNGEN: {
    name: "Leistungen",
    id: "leistungen",
    html: (
      <div className="text">
        <p>
          Meine Behandlung ist individuell auf die Bedürfnisse meiner
          Patientinnen und Patienten abgestimmt. Ein besonderer Schwerpunkt
          meiner Arbeit liegt in der transkulturellen Psychiatrie, insbesondere
          im Zusammenhang mit Migration und psychischen Erkrankungen.
        </p>

        <h3>Meine Behandlungsschwerpunkte:</h3>
        <ul>
          <li>Burnout & Stress</li>
          <li>Affektive Störungen (z. B. Depression)</li>
          <li>Traumafolgestörungen</li>
          <li>Psychosen</li>
          <li>Persönlichkeitsstörungen</li>
        </ul>

        <h3>Mein multimodales Therapieangebot umfasst:</h3>
        <ul>
          <li>
            Ausführliche Beratung und Aufklärung über Erkrankungen und
            Behandlungsmöglichkeiten
          </li>
          <li>Stützende psychiatrische Gespräche</li>
          <li>Krisenintervention</li>
          <li>Medikamentöse Behandlung</li>
          <li>Tiefenpsychologisch fundierte Psychotherapie</li>
          <li>Entspannungsverfahren</li>
        </ul>

        <h3>Weitere Angebote:</h3>
        <p>
          Neben der individuellen Behandlung biete ich auch Beratungen sowie
          Vorträge zu verschiedenen Themen der Psychiatrie und Psychotherapie
          an. Zudem sind auch Videosprechstunden und Online-Psychotherapie
          möglich.
        </p>
      </div>
    ),
  },
  TERMINE: {
    name: "Termine",
    id: "termine",
    html: (
      <div className="text">
        <p>
          Termine können direkt mit mir vereinbart werden – telefonisch unter{" "}
          <b>
            <ContactLink type="phone-landline" />
          </b>{" "}
          (Festnetz) oder{" "}
          <b>
            <ContactLink type="phone-mobile" />
          </b>{" "}
          (Mobil). Ich bin flexibel und passe mich den Bedürfnissen meiner
          Patientinnen und Patienten an. Alternativ können Sie mir auch eine
          E-Mail an{" "}
          <b>
            <ContactLink type="email" />
          </b>{" "}
          senden.
        </p>
      </div>
    ),
  },
  IMPRESSUM: {
    name: "Impressum",
    id: "",
    html: (
      <div className="text">
        <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
        <p>
          Privatpraxis für Psychiatrie und Psychotherapie Maliha Malik
          <br />
          Sachsentor 26
          <br />
          1. Stock
          <br />
          21029 Hamburg-Bergedorf
        </p>

        <h3>Kontakt</h3>
        <p>
          <b>Telefon (Festnetz)</b>: <ContactLink type="phone-landline" />
          <br />
          <b>Telefon (Mobil)</b>: <ContactLink type="phone-mobile" />
          <br />
          <b>E-Mail</b>: <ContactLink type="email" />
        </p>
      </div>
    ),
  },
  VERANSTALTUNGEN: {
    name: "Veranstaltungen",
    id: "veranstaltungen",
    html: (
      <div className="text">
        <p>Ab Dezember 2023 biete ich eine Gruppe für islamische Frauen an.</p>
        <div className="next-event">
          <div className="event-headline">Nächste Veranstaltung</div>
          <div>
            <b>Datum:</b>
            <span>&nbsp;&nbsp;16. Dezember 2023</span>
          </div>
          <div>
            <b>Uhrzeit:</b>
            <span>&nbsp;&nbsp;14:00 Uhr</span>
          </div>
          <div>
            <b>Ort:</b>
            <span>&nbsp;&nbsp;Hamburg</span>
          </div>
          <div>
            <b>Kosten:</b>
            <span>&nbsp;&nbsp;50,00€</span>
          </div>
        </div>
        <div>
          <p>
            Für die Anmeldung senden Sie bitte eine E-Mail an{" "}
            <ContactLink type="email" />. Die genaue Adresse wird Ihnen nach
            erfolgreicher Anmeldung mitgeteilt. Ich freue mich auf Ihre
            Teilnahme!
          </p>
        </div>
      </div>
    ),
  },
};
