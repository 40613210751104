import "./App.css";
import "leaflet/dist/leaflet.css";
import { sectionEnum } from "./sectionEnum";
import NavigationComponent from "./components/navigationComponent";
import TextSection from "./components/textSection";
import Footer from "./components/footer";
import Hero from "./components/hero";
import { useEffect, useState } from "react";

const App = () => {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    const runAfterPageLoad = () => {
      setPageLoaded(true);
    };

    if (document.readyState === "complete") {
      runAfterPageLoad();
    } else {
      window.addEventListener("load", runAfterPageLoad);
    }

    return () => {
      window.removeEventListener("load", runAfterPageLoad);
    };
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      const fragment = window.location.hash;
      if (fragment) {
        const targetId = fragment.substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [pageLoaded]);

  return (
    <>
      <NavigationComponent
        enums={[
          sectionEnum.WILLKOMMEN,
          sectionEnum.VERANSTALTUNGEN,
          sectionEnum.LEISTUNGEN,
          sectionEnum.TERMINE,
          sectionEnum.UEBER,
        ]}
      />
      <Hero />

      <TextSection enum={sectionEnum.WILLKOMMEN} />
      <TextSection enum={sectionEnum.VERANSTALTUNGEN} />
      <TextSection enum={sectionEnum.LEISTUNGEN} />
      <TextSection enum={sectionEnum.TERMINE} />
      <TextSection enum={sectionEnum.UEBER} />

      <Footer />
    </>
  );
};

export default App;
