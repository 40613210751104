import "./footer.css";
import NavigationButton from "./navigationButton";
import { sectionEnum } from "../sectionEnum";
import ContactLink from "./contactLink";

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <h3>Kontakt</h3>
        Privatpraxis für Psychiatrie und Psychotherapie Maliha Malik <br />
        <span>
          Sachsentor 26 <br />
          21029 Hamburg-Bergedorf <br />
          <br />
          <b>Telefon (Festnetz): </b> <ContactLink type="phone-landline" />
          <br />
          <b>Telefon (Mobil): </b> <ContactLink type="phone-mobile" />
          <br />
          <b>Email: </b> <ContactLink type="email" />
        </span>
      </div>
      <span className="spacing" />
      <div>
        <h3>Links</h3>
        <NavigationButton
          enum={sectionEnum.WILLKOMMEN}
          className={"footer-navigation-button"}
        />
        <br />
        <NavigationButton
          enum={sectionEnum.LEISTUNGEN}
          className={"footer-navigation-button"}
        />
        <br />
        <NavigationButton
          enum={sectionEnum.TERMINE}
          className={"footer-navigation-button"}
        />
        <br />
        <NavigationButton
          enum={sectionEnum.UEBER}
          className={"footer-navigation-button"}
        />
        <br />
        <NavigationButton
          enum={sectionEnum.IMPRESSUM}
          className={"footer-navigation-button"}
        />
        <br />
      </div>
    </div>
  );
};

export default Footer;
