import React, { useState } from "react";
import { BiMenu } from "react-icons/bi";
import NavigationButton from "./navigationButton";

const NavigationBarMobile = (props) => {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <div className="navigation-bar navigation-bar-mobile">
      <BiMenu
        style={{
          cursor: "pointer",
          width: "2em",
          height: "2em",
          float: "right",
          padding: "16px 16px 16px 4px",
        }}
        onClick={() => setIsClicked(!isClicked)}
      />
      {isClicked ? (
        React.Children.toArray(
          props.enums.map((_enum, index) => (
            <NavigationButton
              className={"navigation-button-mobile"}
              enum={_enum}
              index={index}
            />
          )),
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default NavigationBarMobile;
