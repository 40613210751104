import React from "react";
import NavigationButton from "./navigationButton";

const NavigationBarDesktop = (props) => {
  return (
    <div className="navigation-bar navigation-bar-desktop ">
      {React.Children.toArray(
        props.enums.map((_enum) => (
          <NavigationButton
            className={"navigation-button-desktop"}
            enum={_enum}
          />
        )),
      )}
    </div>
  );
};

export default NavigationBarDesktop;
