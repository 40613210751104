import React from "react";
import "./textSection.css";

const TextSection = (props) => {
  return (
    <div className="text-section">
      <br />
      <h2 className="text-section-headline">
        <span id={props.enum.id} />
        {props.enum.name}
      </h2>

      {props.enum.html}
    </div>
  );
};

export default TextSection;
